import { TimeSlotRepository } from 'root/repositories/TimeSlotRepository';
import {
  getBusinessDaysDatesRange,
  getMonthlyDateRange,
  safeDateTimeConversion,
  mergeDateRanges,
} from 'root/utils/dateTimeUtils';
import type { DateRange } from 'root/utils/dateTimeUtils';
import type { FulfillmentsClient } from 'root/api/fulfillmentsClient';
import { SchedulingType, DispatchType } from 'root/types/businessTypes';
import type {
  Operation,
  AddressInputAddress,
  Address,
  TimeSlot,
  DispatchLocation,
} from 'root/types/businessTypes';
import { dispatchState } from './DispatchState';
import { makeAutoObservable, toJS, configure, runInAction } from 'mobx';
import type { PlatformControllerFlowAPI, ReportError } from '@wix/yoshi-flow-editor';
import { monitorCallback } from 'root/utils/utils';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import { DateTime } from 'luxon';
import { isToday } from 'root/components/Header/headerUtils';
import { DEFAULT_TIMEZONE } from 'root/api/consts';
import { SchedulingTypeModalState } from 'root/components/DispatchModal/dispatchModalUtils';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import type { CommonAddress } from '@wix/ambassador-wix-atlas-service-web/types';

configure({ isolateGlobalState: true });

export function convertAddressInputToAddress(address: AddressInputAddress): Address {
  const { formatted, location, subdivisions, ...rest } = address;
  return {
    formattedAddress: formatted,
    geocode: location,
    ...rest,
  };
}
export function convertAddressToAddressInput(
  address?: Address,
  addressFormatter?: PlatformControllerFlowAPI['formatAddress']
): AddressInputAddress | undefined {
  if (!address) {
    return undefined;
  }
  const mutualFields = [
    'country',
    'streetAddress',
    'city',
    'subdivision',
    'postalCode',
    'addressLine',
  ];
  const { formattedAddress, geocode, addressLine, streetAddress } = address as Address;
  const formatted =
    (formattedAddress ||
      addressFormatter?.({ address }, { appendCountry: false })
        .filter((part: string) => part && /\S/.test(part))
        .join(', ') ||
      addressLine ||
      streetAddress?.formattedAddressLine) ??
    '';
  return mutualFields.reduce(
    (result, key) => {
      // @ts-expect-error
      if (address[key]) {
        // @ts-expect-error
        result[key] = address[key];
      }
      return result;
    },
    {
      formatted,
      location: geocode,
    }
  );
}

export function areDatesEqual(date1?: Date, date2?: Date) {
  if (!date1 || !date2) {
    return false;
  }
  return date1.getTime() === date2.getTime();
}

export class DispatchModalStore {
  private timeSlotRepository!: TimeSlotRepository;
  private timezone = DEFAULT_TIMEZONE;
  private reportError?: ReportError;
  private sentry?: ErrorMonitor;
  private _availableDeliveryOperations: string[] = [];
  private _deliveryOperations: string[] = [];
  currentDate?: Date;
  timeSlots: TimeSlot[] = [];
  asapTimeSlot?: TimeSlot;
  todaysTimeSlots?: TimeSlot[];
  availableDates: DateTime[] = [];
  availableDispatchTypes: DispatchType[] = [];
  isMultiLocation = false;
  locations?: Map<DispatchType, DispatchLocation[]>;
  private selectedLocationId?: Map<string, string | undefined> = new Map();
  private selectedLocationIndex?: number;
  private isLocationSelected?: boolean;
  cartLocationId?: string;
  isLoading = false;
  isChangingLocation = false;
  constructor(
    private operation: Operation,
    fulfillmentsClient: FulfillmentsClient,
    private addressFormatter?: PlatformControllerFlowAPI['formatAddress'],
    flowAPI?: PlatformControllerFlowAPI,
    fedopsLogger?: FedopsLogger,
    isMultiLocation?: boolean,
    isLocationSelected?: boolean,
    cartLocationId?: string
  ) {
    this.reportError = flowAPI?.reportError;
    this.sentry = flowAPI?.errorMonitor;
    this.sentry?.addBreadcrumb({
      category: 'DispatchModalStore',
      message: 'constructor',
    });
    try {
      this.timeSlotRepository = new TimeSlotRepository(fulfillmentsClient, fedopsLogger, flowAPI);
      this.availableDispatchTypes = dispatchState.availableDispatchTypes;
      this.setLocations();
      const SINGLE_AVAILABLE_LOCATION = 1;

      if (
        this.currentLocations.length === SINGLE_AVAILABLE_LOCATION ||
        this.currentLocations.find((location) => location._id === operation.locationId)
      ) {
        const locationIndex = isLocationSelected
          ? this.currentLocations?.findIndex((location) => location._id === operation.locationId)
          : undefined;
        if (
          locationIndex !== undefined ||
          this.currentLocations?.length === SINGLE_AVAILABLE_LOCATION
        ) {
          this.isLocationSelected = true;
          const locationId =
            this.currentLocations?.length === SINGLE_AVAILABLE_LOCATION
              ? this.currentLocations[0]._id
              : operation.locationId;
          this.setLocationId(locationId, locationIndex);
        }
      }
    } catch (error) {
      this.reportError?.(error as Error);
      this.sentry?.captureException(error as Error);
      console.error(error);
    }
    this.timezone = flowAPI?.controllerConfig.wixCodeApi.site.timezone ?? DEFAULT_TIMEZONE;
    this.isMultiLocation = !!isMultiLocation;
    if (isMultiLocation) {
      this.cartLocationId = cartLocationId;
    }
    this.monitorClassMethods();
    makeAutoObservable(this);
  }

  private monitorClassMethods() {
    this.initTimeAndDates = monitorCallback(
      this.initTimeAndDates.bind(this),
      {
        category: 'DispatchModalStore',
        message: 'initTimeAndDates',
      },
      this.reportError,
      this.sentry
    );
    this.setDate = monitorCallback(
      this.setDate.bind(this),
      {
        category: 'DispatchModalStore',
        message: 'setDate',
      },
      this.reportError,
      this.sentry
    );
    this.setAddress = monitorCallback(
      this.setAddress.bind(this),
      {
        category: 'DispatchModalStore',
        message: 'setAddress',
      },
      this.reportError,
      this.sentry
    );

    this.setAvailableDates = monitorCallback(
      this.setAvailableDates.bind(this),
      {
        category: 'DispatchModalStore',
        message: 'setAvailableDates',
      },
      this.reportError,
      this.sentry
    );

    this.setDispatchType = monitorCallback(
      this.setDispatchType.bind(this),
      {
        category: 'DispatchModalStore',
        message: 'setDispatchType',
      },
      this.reportError,
      this.sentry
    );
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setIsLocationSelected(isLocationSelected: boolean) {
    this.isLocationSelected = isLocationSelected;
  }

  get getSelectedLocationIndex() {
    return this.selectedLocationIndex;
  }

  get currentLocations() {
    return this.getLocationsForDispatchType(dispatchState.selectedDispatchType);
  }

  get pickupLocations() {
    return this.getLocationsForDispatchType(DispatchType.PICKUP);
  }

  getLocationsForDispatchType(dispatchType: DispatchType) {
    return this.locations?.get(dispatchType) ?? [];
  }

  setLocations() {
    this.locations = new Map();
    this.locations.set(
      DispatchType.PICKUP,
      this.getLocationForDispatchType(DispatchType.PICKUP) ?? []
    );
    this.locations.set(
      DispatchType.DELIVERY,
      this.getLocationForDispatchType(DispatchType.DELIVERY) ?? []
    );
  }

  getLocationForDispatchType(selectedDispatchType: DispatchType) {
    const getDispatchStateByOperation = (operation: Operation) => {
      const locationId = operation.locationId ?? '';
      return dispatchState.dispatchStateByLocation?.get(locationId);
    };

    return this.operations
      ?.filter((operation) => {
        const currentDispatchState = getDispatchStateByOperation(operation);
        const isConfigured = !!currentDispatchState?.configuredDispatchTypes.find(
          (d) => d === selectedDispatchType
        );
        return isConfigured;
      })
      ?.map((operation) => {
        const currentDispatchState = getDispatchStateByOperation(operation);
        const acceptOrders = !!currentDispatchState?.availableDispatchTypes.find(
          (d) => d === selectedDispatchType
        );

        return {
          _id: operation.locationId ?? '',
          ...operation.locationDetails,
          operationId: operation.id,
          acceptOrders,
        };
      })
      .sort((a, b) => {
        if (a.operationId === this.operation.id && (a.acceptOrders || !b.acceptOrders)) {
          return -1;
        } else if (b.operationId === this.operation.id && (b.acceptOrders || !a.acceptOrders)) {
          return 1;
        }
        return +b.acceptOrders - +a.acceptOrders;
      }) as DispatchLocation[] | undefined;
  }

  setLocationId(selectedLocationId?: string, index?: number) {
    if (index !== undefined) {
      this.selectedLocationIndex = index;
    }
    this.selectedLocationId?.set(this.dispatchType, selectedLocationId);
    const operation = dispatchState.operations.find((op) => op.locationId === selectedLocationId);

    if (operation) {
      this.operation = operation;
      dispatchState.setCurrentOperationId(operation.id);
    }
  }

  get isLocationChanged() {
    return !!this.cartLocationId && this.cartLocationId !== this.locationId;
  }

  async initTimeAndDates() {
    this.timeSlots = [];
    this.availableDates = [];
    this.currentDate = undefined;
    this.asapTimeSlot = undefined;
    this.todaysTimeSlots = [];
    if (dispatchState.dispatchInfo.address) {
      const startTime = dispatchState.dispatchInfo.selectedTimeSlot?.startTime
        .setZone(this.timezone)
        .startOf('day');
      const date = startTime
        ? new Date(startTime.year, startTime.month - 1, startTime.day)
        : undefined;
      const setDatePromise = date ? this.setDate(date) : Promise.resolve();

      const isPreOrder = this.schedulingType === SchedulingType.PRE_ORDER;
      const shouldFetchAvailableDates =
        isPreOrder ||
        (this.operation.allowAsapFutureHandling &&
          Number(this.operation.businessDaysAheadHandlingOptions) >= 0);

      let setAvailableDatesPromise = Promise.resolve();

      if (shouldFetchAvailableDates) {
        const dateRange = isPreOrder
          ? getMonthlyDateRange(dispatchState.dispatchInfo)
          : getBusinessDaysDatesRange(
              this.timezone,
              Number(this.operation.businessDaysAheadHandlingOptions)
            );
        setAvailableDatesPromise = this.setAvailableDates(dateRange);
      }

      await Promise.all([setDatePromise, setAvailableDatesPromise]).then(async () => {
        !isPreOrder && (await this.setAsapTimeSlot());
        if (
          !isPreOrder &&
          this.availableDates.length > 0 &&
          isToday(this.availableDates[0], this.timezone)
        ) {
          const todayDate = safeDateTimeConversion(this.availableDates[0]);
          const timeSlots = await this.timeSlotRepository.getTimeSlots({
            date: todayDate,
            operationId: this.operation.id,
          });
          runInAction(() => {
            this.todaysTimeSlots = timeSlots;
          });
        }
      });
    }
  }

  get multiLocation() {
    return this.isMultiLocation;
  }

  get multiLocationForCurrentDispatchType() {
    return this.isMultiLocation && this.currentLocations.length > 1;
  }

  get showPickupLocationSelector() {
    return (
      this.multiLocation &&
      !this.isLocationSelected &&
      this.availableDispatchTypes &&
      this.dispatchType === DispatchType.PICKUP
    );
  }

  get locationId() {
    return this.selectedLocationId?.get(this.dispatchType);
  }

  get selectedLocationOperation() {
    return dispatchState.operations.find((operation) => operation.locationId === this.locationId);
  }

  get address() {
    let address = toJS(dispatchState.dispatchInfo.address);
    if (this.dispatchType === DispatchType.PICKUP) {
      address = address ?? this.operation.locationDetails?.address;
    }
    return convertAddressToAddressInput(address, this.addressFormatter);
  }

  get dispatchType() {
    return dispatchState.selectedDispatchType;
  }

  get schedulingType(): SchedulingType {
    if (this.operation.operationType === 'ASAP') {
      return this.operation.allowAsapFutureHandling
        ? SchedulingType.ASAP_AND_FUTURE
        : SchedulingType.ASAP;
    }
    return SchedulingType.PRE_ORDER;
  }

  get schedulingTypeModalState(): SchedulingTypeModalState {
    const dates = this.hasOnlyAsapToday ? this.availableDates.slice(1) : this.availableDates;
    if (this.operation.operationType === 'ASAP') {
      return dates.length > 0
        ? SchedulingTypeModalState.ASAP_AND_FUTURE
        : SchedulingTypeModalState.ASAP_ONLY;
    }
    return SchedulingTypeModalState.PRE_ORDER;
  }

  get hasOnlyAsapToday(): boolean {
    return !!(
      this.todaysTimeSlots?.length === 1 &&
      this.todaysTimeSlots[0]?.startsNow &&
      isToday(this.availableDates[0], this.timezone)
    );
  }

  get selectedTimeSlot() {
    return toJS(dispatchState.dispatchInfo.selectedTimeSlot);
  }

  get asapTimeExact() {
    const asapTimeSlot = toJS(this.asapTimeSlot);
    return (this.selectedTimeSlot?.startsNow ? this.selectedTimeSlot : asapTimeSlot)
      ?.fulfillmentDetails.maxTimeOptions;
  }

  get asapTimeRange() {
    const asapTimeSlot = toJS(this.asapTimeSlot);
    return (this.selectedTimeSlot?.startsNow ? this.selectedTimeSlot : asapTimeSlot)
      ?.fulfillmentDetails.durationRangeOptions;
  }

  get pickupAddress() {
    if (dispatchState.dispatchesInfo[DispatchType.PICKUP]?.address) {
      return dispatchState.dispatchesInfo[DispatchType.PICKUP]?.address;
    }
    return this.isMultiLocation ? this.operation.locationDetails?.address : undefined;
  }

  get pickupLocationName() {
    return this.isMultiLocation ? dispatchState.currentOperation?.locationDetails?.name : '';
  }

  get hasMoreThanOneDispatchType() {
    const dispatchTypes = this.isMultiLocation
      ? dispatchState.configuredDispatchTypes
      : this.availableDispatchTypes;
    return dispatchTypes.length > 1;
  }

  get availableDateRanges(): DateRange[] {
    return mergeDateRanges(toJS(this.availableDates));
  }

  get hasAvailableTimeSlots() {
    return this.timeSlots.length > 0;
  }

  async setAsapTimeSlot() {
    let timeSlot = this.timeSlots.find((slot) => slot.startsNow);
    if (!timeSlot) {
      const isFirstOptionToday =
        this.availableDates.length > 1 && isToday(this.availableDates[0], this.timezone);
      const firstDateTimeSlots = isFirstOptionToday
        ? await this.timeSlotRepository.getTimeSlots({
            date: safeDateTimeConversion(this.availableDates[0]),
            operationId: this.operation.id,
          })
        : [];

      timeSlot = firstDateTimeSlots.find((slot) => slot.startsNow);
    }
    this.asapTimeSlot = timeSlot;
  }

  setTimeSlots(timeSlots: TimeSlot[]) {
    this.timeSlots = timeSlots;
  }

  async setDispatchType(dispatchType: DispatchType) {
    dispatchState.update(dispatchType, {});
    if (!this.locationId && this.isLocationSelected && this.dispatchType === DispatchType.PICKUP) {
      this.isLocationSelected = false;
    }

    if (this.currentLocations?.length === 1) {
      const [location] = this.currentLocations;
      this.isLocationSelected = true;
      this.setLocationId(location._id);
    } else if (this.isLocationSelected) {
      const selectedIndex = this.currentLocations.findIndex(
        (location) => location._id === this.locationId
      );
      this.selectedLocationIndex = selectedIndex;

      const operationId = this.operations.find(
        (operation) => operation.locationId === this.locationId
      )?.id;
      operationId && dispatchState.setCurrentOperationId(operationId);
    }
    if (
      this.isMultiLocation &&
      dispatchType === DispatchType.DELIVERY &&
      dispatchState.dispatchInfo.address &&
      this._deliveryOperations.length === 0
    ) {
      await this.initAddress(dispatchState.dispatchInfo.address);
    } else {
      return this.initTimeAndDates();
    }
  }

  private setCurrentDate(date?: Date) {
    this.currentDate = date;
  }

  async setDate(date: Date) {
    if (!areDatesEqual(this.currentDate, date)) {
      this.setCurrentDate(date);
      const timeSlots = await this.timeSlotRepository.getTimeSlots({
        date,
        operationId: this.operation.id,
      });
      this.setTimeSlots(timeSlots);
      timeSlots.length > 0 && this.setTimeSlot(this.selectedTimeSlot?.id);
    }
  }

  get deliveryOperationsForAddress() {
    return this._deliveryOperations;
  }

  get hasMultipleDeliveryOperationsForAddress() {
    return this._deliveryOperations.length > 1;
  }

  get isCurrentLocationAvailableForAddress() {
    return (
      !this.isMultiLocation ||
      this.dispatchType === DispatchType.PICKUP ||
      this._availableDeliveryOperations.includes(this.operation.id)
    );
  }

  get operations() {
    return dispatchState.operations;
  }

  updateTimeSlot = async (address: CommonAddress, timeSlot?: TimeSlot) => {
    runInAction(() => {
      dispatchState.update(dispatchState.selectedDispatchType, {
        address,
        selectedTimeSlot: timeSlot,
      });
    });
    if (timeSlot) {
      await this.initTimeAndDates();
    } else {
      this.setCurrentDate();
    }
  };

  async setAddress(selectedAddress: AddressInputAddress) {
    const address = convertAddressInputToAddress(selectedAddress);
    await this.initAddress(address);
  }

  async initAddress(address: CommonAddress) {
    this.timeSlotRepository.resetCache(this.dispatchType);
    if (this.isMultiLocation) {
      const [timeSlotsPerOperation, availableLocations] = await Promise.all([
        this.timeSlotRepository.getFirstAvailableTimeSlotPerOperation(address),
        this.dispatchType === DispatchType.DELIVERY
          ? this.timeSlotRepository.getDeliveryLocationsByAddress(address)
          : Promise.resolve([]),
      ]);

      runInAction(() => {
        this._availableDeliveryOperations =
          timeSlotsPerOperation
            ?.filter(({ timeSlot }) => !!timeSlot)
            .map(({ operationId }) => operationId) ?? [];
        this._deliveryOperations = this.operations
          .filter(({ locationId }) => availableLocations.includes(locationId))
          .map(({ id }) => id);
      });

      const operationsWithTimeSlots = timeSlotsPerOperation.filter((t) => !!t.timeSlot);
      const selectedOperation =
        operationsWithTimeSlots.find((t) => t.operationId === this.operation.id) ??
        operationsWithTimeSlots[0];

      if (selectedOperation) {
        const selectedLocationId = this.operations.find(
          (o) => o.id === selectedOperation.operationId
        )?.locationId;
        this.setLocationId(selectedLocationId);
      }

      await this.updateTimeSlot(address, selectedOperation?.timeSlot);
    } else {
      const selectedTimeSlot = await this.timeSlotRepository.getFirstAvailableTimeSlot(address);
      await this.updateTimeSlot(address, selectedTimeSlot);
    }
  }

  async switchToAsapTimeSlot() {
    dispatchState.update(dispatchState.selectedDispatchType, {
      selectedTimeSlot: this.asapTimeSlot,
    });
    if (this.currentDate && !isToday(DateTime.fromJSDate(this.currentDate), this.timezone)) {
      await this.setDate(safeDateTimeConversion(this.availableDates[0]));
    }
  }

  setTimeSlot(timeSlotId?: string) {
    const timeSlot = this.timeSlots.find((slot) => slot.id === timeSlotId);
    if (timeSlot?.id !== this.selectedTimeSlot?.id) {
      if (timeSlot) {
        dispatchState.update(dispatchState.selectedDispatchType, {
          selectedTimeSlot: timeSlot,
        });
      } else {
        dispatchState.update(dispatchState.selectedDispatchType, {
          selectedTimeSlot: this.timeSlots[0],
        });
      }
    }
  }

  setDates(dates: DateTime[]) {
    this.availableDates = dates;
  }

  setIsChangingLocation(isChangingLocation: boolean) {
    this.isChangingLocation = isChangingLocation;
  }

  async setAvailableDates({ from, until }: { from: Date; until: Date }) {
    const dates = await this.timeSlotRepository.getAvailableDates({
      from,
      until,
      operationId: this.operation.id,
    });
    this.setDates(dates);
  }
}
