import type { ISiteApis } from '@wix/yoshi-flow-editor';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import { getSiteStructure } from './siteStructure';
import { PAGE_DATA } from 'root/appConsts/consts';
import { APP_DEF_IDS } from '@wix/restaurants-consts';

const EDITOR_LIGHTBOX_TYPE = 'lightbox';
export const getPageOperationId = async (
  site: ISiteApis,
  sentry: ErrorMonitor
): Promise<string | undefined> => {
  const siteStructure = await getSiteStructure(site);
  const { currentPage } = site;
  const oloPage = siteStructure.pages.find(
    (page) =>
      page.applicationId === APP_DEF_IDS.orders &&
      (page.id === currentPage?.id || currentPage?.type === EDITOR_LIGHTBOX_TYPE)
  );

  const operationId = oloPage?.tpaPageId.slice(PAGE_DATA.pageId.length + 1);

  if (!operationId && oloPage?.tpaPageId) {
    sentry?.addBreadcrumb({
      category: 'get operationId from page',
      message: `Failed to get operationGroupId from page ${oloPage.tpaPageId}`,
    });
    sentry?.captureException(
      new Error(`Failed to get operationGroupId from page ${oloPage.tpaPageId}`)
    );
  }
  return operationId;
};
